import { useEffect, useState } from "react";
import { Artist, Record } from "../model/database";
import { useParams } from "react-router-dom";
import { getArtistById, getRecordsByArtistId } from "../lib/firestore";
import { getCacheStatus } from "../lib/utils";

import Navbar from "../components/Navbar";
import ReviewGrid from "../components/ReviewGrid";
import Modal from "../ui-controls/Modal";
import RecordProfile from "./RecordProfile";

import styles from "../styles/ArtistProfile.module.css";
import Medals from "../components/Medals";

function ArtistProfile(props: any) {
  const { id } = useParams() || props;

  const [mainArtist, setMainArtist] = useState({} as Artist);
  const [records, setRecords] = useState([] as Record[]);
  const [selectedRecord, setSelectedRecord] = useState({} as Record);
  const [showModal, setShowModal] = useState(false);
  const [averageRatings, setAverageRatings] = useState("Calculating");

  const handleHideModal = (e: Event) => {
    const closeRegExp = /(Modal_mask)|(mdi-close)/g;
    if (closeRegExp.test((e.target as HTMLElement).className)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    async function setDataAndCache() {
      const promiseRecordsByArtist = getRecordsByArtistId(id!).then(
        (records) => {
          setRecords(records);
          localStorage.setItem(`artist/${id}/records`, JSON.stringify(records));
        }
      );
      const promiseArtist = getArtistById(id!).then((artist) => {
        setMainArtist(artist);

        localStorage.setItem(`artist/${id}`, JSON.stringify(artist));
      });
      return Promise.all([promiseRecordsByArtist, promiseArtist]);
    }

    try {
      const { clearCache, cacheExpiry } = getCacheStatus();
      if (cacheExpiry < new Date() || clearCache) {
        localStorage.clear();
        setDataAndCache().then(() => {
          const now = new Date();
          localStorage.setItem(
            "cacheExpiry",
            now.setDate(now.getDate() + 1).toString()
          );
        });

        return;
      }

      const cachedArtist = JSON.parse(localStorage.getItem(`artist/${id}`)!);
      const cachedArtistRecords = JSON.parse(
        localStorage.getItem(`artist/${id}/records`)!
      );

      if (!cachedArtist || !cachedArtistRecords) {
        setDataAndCache();
        return;
      }

      setRecords(cachedArtistRecords);
      setMainArtist(cachedArtist);
    } catch (e) {
      console.log(e);
    }
  }, [id]);

  useEffect(() => {
    if (!mainArtist.name) return;
    document.title = `WITOM - ${mainArtist.name}`;
    return () => {
      document.title = `What I Think Of Music`;
    };
  }, [mainArtist]);

  useEffect(() => {
    if (!records.length) return;

    const ratedRecords = records.filter((record) => record.review);
    if (!ratedRecords.length) return setAverageRatings("N/a");

    setAverageRatings(
      (
        ratedRecords.reduce((sum, record) => sum + record.rating, 0) /
        ratedRecords.length
      ).toFixed(2)
    );
  }, [records]);

  return (
    <div className={styles.container}>
      <Navbar />
      <hr />
      <div className={styles.hero}>
        <div className={styles.profile}>
          {mainArtist.picture ? (
            <img src={mainArtist.picture} alt={mainArtist.name} />
          ) : records.length ? (
            <img src={records[0].cover_art} alt={records[0].name} />
          ) : null}
          <h2>{mainArtist.name}</h2>
        </div>
        <div className={styles.stats}>
          <div className="icon-container">
            {mainArtist.spotify_id && (
              <a href={mainArtist.spotify_id}>
                <i className="mdi mdi-spotify"></i>
              </a>
            )}
            {mainArtist.apple_id && (
              <a href={mainArtist.apple_id}>
                <i className="mdi mdi-apple"></i>
              </a>
            )}
            {mainArtist.youtube_id && (
              <a href={mainArtist.youtube_id}>
                <i className="mdi mdi-youtube"></i>
              </a>
            )}
          </div>
          <span>Average Ratings: {averageRatings}</span>
          <Medals records={records} />
        </div>
      </div>
      <hr />
      <div className="spacing"></div>
      <ReviewGrid
        records={records}
        showSearch={false}
        onSelectRecord={(record: Record) => {
          if (!record.review) {
            return;
          }
          setShowModal(true);
          setSelectedRecord(record);
        }}
      />
      {showModal && (
        <Modal onHideModal={handleHideModal}>
          <RecordProfile record={selectedRecord}></RecordProfile>
        </Modal>
      )}
    </div>
  );
}

export default ArtistProfile;
