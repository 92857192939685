import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./views/Home";
import RecordProfile from "./views/RecordProfile";
import ArtistProfile from "./views/ArtistProfile";

import "./styles/index.css";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/album/:id" element={<RecordProfile />} />
        <Route path="/artist/:id" element={<ArtistProfile />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
