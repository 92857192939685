import Review from "../components/Review";
import Tabs from "../ui-controls/Tabs";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRecordById } from "../lib/firestore";
import { getCacheStatus } from "../lib/utils";

import { Tab } from "../model/components";
import { Artist, Record } from "../model/database";

import styles from "../styles/RecordProfile.module.css";
import Navbar from "../components/Navbar";
import TabHeader from "../ui-controls/TabHeader";

function RecordProfile(props: any) {
  const { id } = useParams() || props;
  const [record, setRecord] = useState(props.record || ({} as Record));
  const [tabs, setTabs] = useState([] as Tab[]);

  function setSelectedTab(id: string) {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        active: tab.id === id && !tab.active,
      }))
    );
  }

  function streamingPlatformID(id: string) {
    switch (id) {
      case "apple":
        return record.apple_id;
      case "youtube":
        return record.youtube_id;
      case "spotify":
        return record.spotify_id;
    }
  }

  useEffect(() => {
    async function setDataAndCache() {
      const promiseRecord = getRecordById(id!).then((record) => {
        if (!record) throw new Error("Record not found");

        setRecord(record);

        localStorage.setItem(`record/${id}`, JSON.stringify(record));
      });

      return Promise.all([promiseRecord]);
    }

    if (props.record) return;
    const { clearCache, cacheExpiry } = getCacheStatus();

    if (cacheExpiry < new Date() || clearCache) {
      localStorage.clear();
      setDataAndCache().then(() => {
        const now = new Date();
        localStorage.setItem(
          "cacheExpiry",
          now.setDate(now.getDate() + 1).toString()
        );
      });
    } else {
      const cachedRecords = JSON.parse(localStorage.getItem(`record/${id}`)!);
      cachedRecords ? setRecord(cachedRecords) : setDataAndCache();
    }
  }, [id, props.record]);

  useEffect(() => {
    if (!record.name) return;
    const { apple_id, spotify_id, youtube_id } = record;
    const playerTabs = [];
    const newIframe = (name: string, src: string) => (
      <iframe
        title={name}
        src={src}
        width="100%"
        allow="autoplay *; encrypted-media *; fullscreen *"
        allowFullScreen={false}
      />
    );

    apple_id &&
      playerTabs.push({
        id: "apple",
        active: false,
        header: <TabHeader type="icon" icon="apple" />,
        body: newIframe(`${record.name}_apple`, apple_id),
      });
    youtube_id &&
      playerTabs.push({
        id: "youtube",
        active: false,
        header: <TabHeader type="icon" icon="youtube" />,
        body: newIframe(`${record.name}_youtube`, youtube_id),
      });
    spotify_id &&
      playerTabs.push({
        id: "spotify",
        active: false,
        header: <TabHeader type="icon" icon="spotify" />,
        body: newIframe(`${record.name}_spotify`, spotify_id),
      });

    setTabs(playerTabs);

    const recordArtistName = `${record.name} - ${record.artists
      .map((a: Artist) => a.name)
      .join(", ")}`;
    document.title = `WITOM - ${recordArtistName}`;
    return () => {
      document.title = `What I Think Of Music`;
    };
  }, [record]);

  return (
    <div className={`${styles.container} ${props.record ? "" : "pb-2"}`}>
      {!props.record && (
        <>
          <Navbar /> <hr />
        </>
      )}

      <div className={styles.header}>
        <div className={styles["title-container"]}>
          <div className={styles.back}>
            {props.record && (
              <a className="center" href={`/album/${record.id}`}>
                Go to album page
              </a>
            )}
          </div>
          <h2 className={styles.record}>{record.name}</h2>
          <div className="center">
            {record.artists &&
              record.artists.map(
                (artist: { id: string; name: string }, i: number) => {
                  return (
                    <h3 key={artist.id}>
                      <a href={`/artist/${artist.id}`}>{artist.name}</a>
                      {i !== record.artists.length - 1 && <span> &</span>}
                    </h3>
                  );
                }
              )}
          </div>
          <h3 className={styles.year}>{record.release_year}</h3>
          {props.record && (
            <div className="icon-container">
              {tabs.map((tab) => {
                return (
                  <a
                    key={tab.id}
                    href={streamingPlatformID(tab.id)
                      .replace(/embed./, "")
                      .replace("videoseries", "playlist")}
                  >
                    <i className={`mdi mdi-${tab.id}`} />
                  </a>
                );
              })}
            </div>
          )}
        </div>
        <img alt={record.name} src={record.cover_art} />
        {record.rating && (
          <div className={styles.rating}>{record.rating.toFixed(1)}</div>
        )}
      </div>
      {record.review && <Review review={record.review} />}
      {!props.record && (
        <Tabs tabs={tabs} onSetSelectedTab={setSelectedTab}>
          {tabs.find((tab) => tab.active)?.body}
        </Tabs>
      )}
    </div>
  );
}

export default RecordProfile;
